import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { editEEStatutItemsReference, editEEStatutLabelsReference, editEEStatutMapReference } from 'app/services/commons';
import { EvaluationExigenceService } from 'app/services/evaluation-exigence.service';
import { eeStatuses, labelsByStatus, eeCssByStatus, informativeStatus } from './status.const'

@Component({
  selector: 'app-ee-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class EvaluationExigenceStatusComponent {

  @Input() eeModelId: number = -1
  precedentEeModelId: number

  @Input() @Output('statut') statut: number
  @Input() minimal: boolean
  @Input() popUpContainer: any
  @Output('onValueChangedAction') event_: EventEmitter<any> = new EventEmitter<any>();

  statuses: any = eeStatuses
  cssByStatus: any = eeCssByStatus
  eeLabelsByStatus: any = labelsByStatus

  constructor(public translate: TranslateService, public eeService: EvaluationExigenceService) { }

  ngAfterViewInit() {

    this.translate.get(
      [
        'common-term*statut-AE',
        'common-term*statut-C',
        'common-term*statut-NC',
        'common-term*statut-SO',
        'common-term*statut-AV'
      ]
      )
      .subscribe(val => {
        this.statuses[0].text = val['common-term*statut-AE'];
        this.statuses[1].text = val['common-term*statut-C'];
        this.statuses[2].text = val['common-term*statut-NC'];
        this.statuses[3].text = val['common-term*statut-SO'];
        this.statuses[4].text = val['common-term*statut-AV'];
        
      });


      this.translate.get(
        [
          'common-term*hint-AE',
          'common-term*hint-C',
          'common-term*hint-NC',
          'common-term*hint-SO',
          'common-term*hint-AV',
          'common-term*hint-I',
          'common-term*hint-X'
        ]
        )
        .subscribe(val => {
          this.eeLabelsByStatus[1] = val['common-term*hint-AE'];
          this.eeLabelsByStatus[2] = val['common-term*hint-C'];
          this.eeLabelsByStatus[3] = val['common-term*hint-NC'];
          this.eeLabelsByStatus[4] = val['common-term*hint-SO'];
          this.eeLabelsByStatus[5] = val['common-term*hint-AV'];
          this.eeLabelsByStatus[10] = val['common-term*hint-I'];
          this.eeLabelsByStatus[''] = val['common-term*hint-X'];
        });

        
      
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.eeModelId){
      this.precedentEeModelId = changes.eeModelId.previousValue
      this.eeModelId = changes.eeModelId.currentValue 
    }
  }

  centrationClass(statut){
    let res: string = ''
    if(!this.minimal){
      res = "centration ".concat(this.cssByStatus[statut]) 
      if(this.popUpContainer === undefined){
        res = res.concat(" centration-max")
      }
    }
    return res
  }

  labelStatus(statut: number){
    return statut !== undefined ? this.eeLabelsByStatus[statut] : this.eeLabelsByStatus['']
  }

  // Throw onValueChanged event
  emit(event: any){
   // We stay on ee to change status value
   if(this.precedentEeModelId != this.eeModelId){
      this.event_.emit(event) 
    }
    if(this.minimal){
      this.eeService.statusSubject.next(event)
    }
  }

}
