import { Injectable } from '@angular/core';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import notify from 'devextreme/ui/notify';
import { jsToSQLFilter, getHttpHeaders, replaceInFilter, transformStatutInFilter } from 'app/services/commons';
import { AppConfig } from 'app/app.config';
import { QSE_LAST_LANGUE, QSE_USER_INFO } from './user.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';


let _StatutDataSource = [
  { value: 1, label: "À évaluer" },
  { value: 2, label: "Conforme" },
  { value: 3, label: "Non conforme" },
  { value: 4, label: "Sans objet" },
  { value: 5, label: "À vérifier" }
];



let _PrioriteDataSource = [
  { value: 0, label: "0 (Inexistante)" },
  { value: 1, label: "1 (Faible)" },
  { value: 2, label: "2 (Modérée)" },
  { value: 3, label: "3 (importante)" }
];

let _StatutOpportuniteDataSource = [
  { value: 1, label: "À évaluer" },
  { value: 2, label: "Opportunité" },
  { value: 3, label: "Non conforme" },
  { value: 4, label: "Sans objet" },
  { value: 5, label: "À vérifier" }
];



let _StatutInfoDataSource = [
  { value: 0, label: "?" },
  { value: 1, label: "À évaluer" },
  { value: 2, label: "Conforme" },
  { value: 3, label: "Non conforme" },
  { value: 4, label: "Sans objet" },
  { value: 5, label: "À vérifier" },
  { value: 10, label: "Informatif" }
];


let _StatutFilterDataSource = [
  { value: 0, label: "Toutes", filter: undefined },
  { value: 1, label: "À évaluer", filter: [["e.aevaluer", "=", 1], "and", ["e.pourinfo", "=", 0]] },
  { value: 2, label: "Déja évalué", filter: [["e.aevaluer", "=", 0], "and", ["e.pourinfo", "=", 0]] },
  { value: 3, label: "Conforme", filter: ["e.conforme", "=", 1] },
  { value: 4, label: "Non conforme", filter: ["e.nonconforme", "=", 1] },
  { value: 5, label: "Sans objet", filter: ["e.sansobjet", "=", 1] },
  { value: 6, label: "À vérifier", filter: ["e.averifier", "=", 1] },
  { value: 7, label: "Informatif", filter: ["e.pourinfo", "=", 1] },
];

let _StatutFilterDataSourceCS = new CustomStore({
  loadMode: 'raw',
  key: 'value',
  load: function (loadOptions: any) : any{ return Promise.resolve(
[
  { value: 0, label: "Toutes"  },
  { value: 1, label: "À évaluer"  },
  { value: 2, label: "Déja évalué"  },
  { value: 3, label: "Conforme" },
  { value: 4, label: "Non conforme"  },
  { value: 5, label: "Sans objet"  },
  { value: 6, label: "À vérifier"  },
  { value: 7, label: "Informatif"  },
]);
  }})

let _RefUsageFilterDataSource = [
  { value: 0, label: "Tous", filter: ["1", "=", 1] },
  { value: 1, label: "Avec des exigences restant à évaluer", filter: ["SUM(e.aevaluer)", ">", 0] },
  { value: 2, label: "Déjà complètement évalués", filter: [ "SUM(e.aevaluer)", "=", 0] }, // and pourinfo=0
  { value: 3, label: "Avec des non conformes", filter: [ "SUM(e.nonconforme)", ">", 0] },
  { value: 4, label: "Avec des à vérifier", filter: [ "SUM(e.averifier)", ">", 0] }

 // { value: 1, label: "Avec des exigences restant à évaluer", filter: [["SUM(e.aevaluer)", ">", 0], "and", ["(SUM(e.aevaluer)-SUM(e.pourinfo))", "<", 0]] },
 // { value: 2, label: "Déjà complètement évalués", filter: [ ["SUM(e.aevaluer)", "=", 0], "or",["(SUM(e.aevaluer)-SUM(e.pourinfo))", "=", 0]] } // and pourinfo=0

 
];


let _propagationActionDataSource = [
  { value: 0, label: "Propager à la référence", disabled: false, info: "Propage l'évaluation de l'exigence courante sur toutes les autres exigences de la référence à laquelle l'exigence courante est associée." },
  { value: 1, label: "Propager à une sélection d'exigences", disabled: false, info: "Propage l'évaluation de l'exigence courante à une sélection des autres exigences de la référence à laquelle l'exigence courante est associée." },
  { value: 2, label: "Propager à d'autres références", disabled: false, info: "Propage l'évaluation de l'exigence courante à toutes les autres exigences des références sélectionnées classées au même point de la taxonomie." },
  { value: 3, label: "Propager à un noeud de la taxonomie", disabled: false, info: "Propage l'évaluation de l'exigence courante à toutes les autres exigences de toutes les références classées sous le noeud taxonomie de votre choix." },
  { value: 4, label: "Propager à d'autres sites", disabled: true, info: "Propage l'évaluation de l'exigence courante à toutes les exigences des autres sites partageant le même profil réglementaire " },
  { value: 5, label: "Propager réf. vers réf. sites.", disabled: true, info: "Propage les évaluations de la référence courante aux références des autres sites sélectionnés." },
  { value: 6, label: "Propager tout le noeud à d'autres sites", disabled: false, info: "Propage les évaluations du noeud courant aux évaluations du même noeud pour les autres sites sélectionnés." }

]


let _ChangeStatutDataSource = [
  { value: 0, label: "Aucun filtrage" },
  { value: 1, label: "Conformités expirées" },
  { value: 2, label: "Exigences modifiées" },
  { value: 3, label: "Exigences créées" }
];

export class EvaluationExigence {
  actions: string;
  statut: number;
  uniteDelai: number;
  titreReference: string;
  serviceResponsable: string;
  notes: string;
  moyens:string;
  montant:number;
  facilite: boolean;
  efficacite: number;
  gravite: number;
  frequence: number;
  dateLimite: Date;
dateRealisation: Date;
dateResolution: Date;
dateRevision: Date;

}



@Injectable({  providedIn: 'root' })
export class EvaluationExigenceService {

  ventilDataSource: any;
  ventilDataSource_SANS_SANSOBJETS:any;
  usagesDataSource: any;
  exigencesDataSource: any;
  multisiteeeDataSource: any;
  actionModelDataSource: any;
  agregeeeDataSource: any;
  revisionDataSource: any;
  resolutionDataSource: any;
  conformesDataSource: any;
  conformesDataSourceAvecValidite: any;
  sansobjetsDataSourceAvecValidite: any;
  opportunitesDataSource: any;
  sansobjetsDataSource: any;
  pivotDataSource: any;
  groupeStatsDataSource: any;
  multisiteStatsDataSource : any;
  parReferenceStatsDataSource: any;

  eeFlatEcheancierDataSource: any ;
  eeFlatEcartsDataSource: any ;
  eeFlatRevisionsDataSource: any ;
  eeFlatConformesDataSource: any ;
  eeFlatSansobjetsDataSource: any ;
  eeFlatOpportunitesDataSource: any;
  tlabels: any = {};

  statusSubject = new Subject<any>()

  public restrictedParents: any;
  public currentAudit: any = [0, 0, 0];
  public filtresAdditionels: any = [0, 0, 0];
  public modelPreFilter: any = [0, 0];

  copyPasteEE(src_id: any, tgt_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/ee/paste/" + src_id + "/" + tgt_id, { headers: getHttpHeaders() }).toPromise();
  }

  copyPasteEEtoRefusages(src_id: any, tgt_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/ee/pastetousages/" + src_id + "/" + encodeURIComponent(tgt_id), { headers: getHttpHeaders() }).toPromise();
  }

  copyPasteEEtoEEset(src_id: any, tgt_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/ee/pastetoees/" + src_id + "/" + encodeURIComponent(tgt_id), { headers: getHttpHeaders() }).toPromise();
  }

  copyPasteEEtoTaxonode(src_id: any, tgt_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/ee/pastetonode/" + src_id + "/" + encodeURIComponent(tgt_id), { headers: getHttpHeaders() }).toPromise();
  }

  copyPasteEEtoAuditSites(src_id: any, tgt_id, responsables, overwriteresponsables) {
    return this.http.get<any>(AppConfig.settings.api + "/api/ee/pastetoauditsites/" + src_id + "/" + encodeURIComponent(tgt_id)+ "?responsables="+encodeURIComponent(responsables), { headers: getHttpHeaders() }).toPromise();
  }

  propagateNoeud2AutresSites(node_id:any, audit_id, tgtauditids, aevaluer:boolean, responsables) {
    return this.http.get<any>(AppConfig.settings.api +
       "/api/ee/propagernoeudversnoeudautresaudits/" + node_id + "/" + audit_id + "/" + encodeURIComponent(tgtauditids) + "?ae="+aevaluer+ "&responsables="+encodeURIComponent(responsables), { headers: getHttpHeaders() }).toPromise();
  }

  propagateEE2AutresSites(src_id:any, tgtauditids, aevaluer:boolean) {
     return this.http.get<any>(AppConfig.settings.api + "/api/ee/propagerversautresaudits/" + src_id + "/" + encodeURIComponent(tgtauditids) + "?ae="+aevaluer, { headers: getHttpHeaders() }).toPromise();
  }

  copyPasteReftoRefsSites(src_id: any, tgt_id, responsables) {
    return this.http.get<any>(AppConfig.settings.api + "/api/ee/pasteref2refs/" + src_id + "/" + encodeURIComponent(tgt_id)+"?responsables="+encodeURIComponent(responsables), { headers: getHttpHeaders() }).toPromise();
  }

  getDatesInfo(audit_id:any) {
    return this.http.get<any>(AppConfig.settings.api +"/api/ee/dateinfo/"  + audit_id, { headers: getHttpHeaders()}).toPromise();
  }

  notifierResponsable(ee_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/ee/notifier/" + ee_id, { headers: getHttpHeaders() }).toPromise();
  }

  notifierResponsableOpportunite(ee_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/ee/notifieropportunite/" + ee_id, { headers: getHttpHeaders() }).toPromise();
  }

  getEvaluationArchive(ee_id:any) {
    return this.http.get<any>(AppConfig.settings.api +"/api/ee/archive/"+ ee_id, { headers: getHttpHeaders() }  ).toPromise();
  }

  deleteEvaluationArchive(ee_id:any) {
    return this.http.delete<any>(AppConfig.settings.api +"/api/ee/archive/delete/" + ee_id, { headers: getHttpHeaders() } ).toPromise();
  }

  chercheUsages(audit_id:any, substring:any) {
    return this.http.get<any>(AppConfig.settings.api +"/api/ee/search/usage/"  + audit_id + "?filter="+encodeURIComponent(substring), { headers: getHttpHeaders()}).toPromise();
  }

  chercheUsagesGroupe(audit_id:any, substring:any) {
    return this.http.get<any>(AppConfig.settings.api +"/api/ee/search/usage/groupe/"  + audit_id + "?filter="+encodeURIComponent(substring), { headers: getHttpHeaders()}).toPromise();
  }



  transferVersSymalean(ee_id:any) {
    return this.http.get<any>(AppConfig.settings.api +"/api/ee/symalean/send/"  + ee_id , { headers: getHttpHeaders()}).toPromise();
  }

  transferVersPartenaire(slug:string, ee_id:any) {
    return this.http.get<any>(AppConfig.settings.api +"/api/ee/"+slug+"/send/"  + ee_id , { headers: getHttpHeaders()}).toPromise();
  }

  transferPlanDActionVersSymalean(audit_id:any) {
    return this.http.get<any>(AppConfig.settings.api +"/api/ee/symalean/plandaction/send/"  + audit_id , { headers: getHttpHeaders()}).toPromise();
  }

  transferPlanDActionVersPartenaire(slug:string,audit_id:any) {
    return this.http.get<any>(AppConfig.settings.api +"/api/ee/"+slug+"/plandaction/send/"  + audit_id , { headers: getHttpHeaders()}).toPromise();
  }

  getGanttDataBundle(audit_id:any) {
    return this.http.get<any>(AppConfig.settings.api +"/api/ee/gantt/"  + audit_id, { headers: getHttpHeaders()}).toPromise();
  }


  listEvaluateursPourAudit(audit_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/ee/evaluateurs/" + encodeURIComponent(audit_id), { headers: getHttpHeaders() }).toPromise();
  }


  listEEsEcheancierPourAuditEtActionIdentique(audit_id: any, action:any) {
    //return this.http.get<any>(AppConfig.settings.api + "/api/ee/sameaction/" + encodeURIComponent(audit_id), { headers: getHttpHeaders() }).toPromise()
    return this.http.put<any>(AppConfig.settings.api + "/api/ee/sameaction", {audit  :audit_id, action:action}, { headers: getHttpHeaders() }).toPromise()
  }


  constructor(private http: HttpClient, public translate: TranslateService) {

    let SERVICE_URL = AppConfig.settings.api + "/api/ee/";
    var currentAudit = this.currentAudit;
    var extraFilters = this.filtresAdditionels;

    this.translate.use(sessionStorage.getItem(QSE_LAST_LANGUE));
    this.translate.get(
      [ 
        'common-term*conforme',
        'common-term*non conforme',
        'common-term*à vérifier',
        'common-term*à évaluer',
        'common-term*sans objet',
        'common-term*déjà évalué',
        'statistiques-abrev*pour info',
        'common-term*statut-AE',
        'common-term*statut-AV',
        'common-term*statut-C',
        'common-term*statut-I',
        'common-term*statut-NC',
        'common-term*statut-SO',
        'services-common-notify*màj',


        'audit-editeur-propagationAction*Propager à la référence',
'audit-editeur-propagationAction*Propager à une sélection dexigences',
'audit-editeur-propagationAction*Propager à dautres références',
'audit-editeur-propagationAction*Propager à un noeud de la taxonomie',
'audit-editeur-propagationAction*Propager à dautres sites',
'audit-editeur-propagationAction*Propager réf. vers réf. sites.',
'audit-editeur-propagationActionInfo*Propager à la référence',
'audit-editeur-propagationActionInfo*Propager à une sélection dexigences',
'audit-editeur-propagationActionInfo*Propager à dautres références',
'audit-editeur-propagationActionInfo*Propager à un noeud de la taxonomie',
'audit-editeur-propagationActionInfo*Propager à dautres sites',
'audit-editeur-propagationActionInfo*Propager réf. vers réf. sites.',

'common-term*toutes',
'audit-editeur-statutFilterDS*Déjà évalué',
'audit-editeur-statutFilterDS*Conforme',
'audit-editeur-statutFilterDS*Non conforme',
'audit-editeur-statutFilterDS*À vérifier',
'audit-editeur-statutFilterDS*Sans objet',
'audit-editeur-statutFilterDS*Informatif',
'audit-editeur-statutFilterDS*À évaluer',

'common-term*tous',
'audit-editeur-rfusageFilterDS*Avec des exigences restant à évaluer',
'audit-editeur-rfusageFilterDS*Déjà complètement évalués',
'audit-editeur-rfusageFilterDS*Avec des non conformes',
'audit-editeur-rfusageFilterDS*Avec des à vérifier',

'common-term*À évaluer',
'common-term*Conforme',
'common-term*Non conforme',
'common-term*Sans objet',
'common-term*À vérifier',
'common-term*Opportunité',
'common-term*Informatif',

'audit-editeur-propagerNoeudSurAuditsDialog_PropagerClicked*notify1a',
'audit-editeur-propagerNoeudSurAuditsDialog_PropagerClicked*notify1b',
'audit-editeur-propagerNoeudSurAuditsDialog_PropagerClicked*notify2',
'audit-editeur-propagationActionInfo*Propager-noeud-sur-audits',
'audit-editeur-propagationAction*Propager-noeud-sur-audits',
'audit-editeur-popup*propage-noeud-sites-title',
'audit-editeur-popup*propage-noeud-sites-tip',
'audit-editeur-priorite-0*label',
'audit-editeur-priorite-1*label',
'audit-editeur-priorite-2*label',
'audit-editeur-priorite-3*label'

      ]
      )
        .subscribe(val => {
          this.tlabels = val;

          _StatutFilterDataSourceCS.load().then( r=>{
          r[0].label = val['common-term*toutes'];
          r[1].label = val['audit-editeur-statutFilterDS*À évaluer'];
          r[2].label = val['audit-editeur-statutFilterDS*Déjà évalué'];
          r[3].label = val['audit-editeur-statutFilterDS*Conforme'];
          r[4].label = val['audit-editeur-statutFilterDS*Non conforme'];
          r[5].label = val['audit-editeur-statutFilterDS*Sans objet'];
          r[6].label = val['audit-editeur-statutFilterDS*À vérifier'];
          r[7].label = val['audit-editeur-statutFilterDS*Informatif'];
          });

          _StatutFilterDataSource[0].label = val['common-term*toutes'];
          _StatutFilterDataSource[1].label = val['audit-editeur-statutFilterDS*À évaluer'];
          _StatutFilterDataSource[2].label = val['audit-editeur-statutFilterDS*Déjà évalué'];
          _StatutFilterDataSource[3].label = val['audit-editeur-statutFilterDS*Conforme'];
          _StatutFilterDataSource[4].label = val['audit-editeur-statutFilterDS*Non conforme'];
          _StatutFilterDataSource[5].label = val['audit-editeur-statutFilterDS*Sans objet'];
          _StatutFilterDataSource[6].label = val['audit-editeur-statutFilterDS*À vérifier'];
          _StatutFilterDataSource[7].label = val['audit-editeur-statutFilterDS*Informatif'];
          
          _RefUsageFilterDataSource[0].label = val['common-term*tous'];
          _RefUsageFilterDataSource[1].label = val['audit-editeur-rfusageFilterDS*Avec des exigences restant à évaluer'];
          _RefUsageFilterDataSource[2].label = val['audit-editeur-rfusageFilterDS*Déjà complètement évalués'];
          _RefUsageFilterDataSource[3].label = val['audit-editeur-rfusageFilterDS*Avec des non conformes'];
          _RefUsageFilterDataSource[4].label = val['audit-editeur-rfusageFilterDS*Avec des à vérifier'];


          _propagationActionDataSource[0].label =val['audit-editeur-propagationAction*Propager à la référence'];
          _propagationActionDataSource[1].label =val['audit-editeur-propagationAction*Propager à une sélection dexigences'];
          _propagationActionDataSource[2].label =val['audit-editeur-propagationAction*Propager à dautres références'];
          _propagationActionDataSource[3].label =val['audit-editeur-propagationAction*Propager à un noeud de la taxonomie'];
          _propagationActionDataSource[4].label =val['audit-editeur-propagationAction*Propager à dautres sites'];
          _propagationActionDataSource[5].label =val['audit-editeur-propagationAction*Propager réf. vers réf. sites.'];
          _propagationActionDataSource[6].label =val['audit-editeur-propagationAction*Propager-noeud-sur-audits'];
  
          _propagationActionDataSource[0].info =val['audit-editeur-propagationActionInfo*Propager à la référence'];
          _propagationActionDataSource[1].info =val['audit-editeur-propagationActionInfo*Propager à une sélection dexigences'];
          _propagationActionDataSource[2].info =val['audit-editeur-propagationActionInfo*Propager à dautres références'];
          _propagationActionDataSource[3].info =val['audit-editeur-propagationActionInfo*Propager à un noeud de la taxonomie'];
          _propagationActionDataSource[4].info =val['audit-editeur-propagationActionInfo*Propager à dautres sites'];
          _propagationActionDataSource[5].info =val['audit-editeur-propagationActionInfo*Propager réf. vers réf. sites.'];
          _propagationActionDataSource[6].info =val['audit-editeur-propagationActionInfo*Propager-noeud-sur-audits'];

         
          _StatutDataSource[0].label = val['common-term*À évaluer'];
          _StatutDataSource[1].label = val['common-term*Conforme'];
          _StatutDataSource[2].label = val['common-term*Non conforme'];
          _StatutDataSource[3].label = val['common-term*Sans objet'];
          _StatutDataSource[4].label = val['common-term*À vérifier'];

          _StatutOpportuniteDataSource[0].label = val['common-term*À évaluer'];
          _StatutOpportuniteDataSource[1].label = val['common-term*Opportunité'];
          _StatutOpportuniteDataSource[2].label = val['common-term*Non conforme'];
          _StatutOpportuniteDataSource[3].label = val['common-term*Sans objet'];
          _StatutOpportuniteDataSource[4].label = val['common-term*À vérifier'];

          _PrioriteDataSource[0].label = val['audit-editeur-priorite-0*label'];
          _PrioriteDataSource[1].label = val['audit-editeur-priorite-1*label'];
          _PrioriteDataSource[2].label = val['audit-editeur-priorite-2*label'];
          _PrioriteDataSource[3].label = val['audit-editeur-priorite-3*label'];

          _StatutInfoDataSource[1].label = val['common-term*À évaluer'];
          _StatutInfoDataSource[2].label = val['common-term*Conforme'];
          _StatutInfoDataSource[3].label = val['common-term*Non conforme'];
          _StatutInfoDataSource[4].label = val['common-term*Sans objet'];
          _StatutInfoDataSource[5].label = val['common-term*À vérifier'];
          _StatutInfoDataSource[6].label = val['common-term*Informatif'];






      });

    let zis = this;

    this.ventilDataSource = new CustomStore({

      key: "id",

      load: function (loadOptions: any) : any{
        let params = "";
        let filter = "";
        var pp = '?';

       // if(AppConfig.settings.trace) console.log("ventilDataSource, loadOptions=", loadOptions);

        if (loadOptions.filter && loadOptions.filter.parentIds)
          loadOptions.parentIds = loadOptions.filter.parentIds;

        if (loadOptions.parentIds && loadOptions.parentIds.length == 0)
          loadOptions.parentIds = [0];
        if (loadOptions.parentIds)
          params = "children/" + loadOptions.parentIds.join(","); // picking the last parentId of the list


        if (this.restrictedParents) {
          if(AppConfig.settings.trace) console.log("HIT !");
          params = "children/0?retain=" + this.restrictedParents.join(",");
        }
        else
          if (loadOptions.filter && params.length == 0 && loadOptions.filter[2] && loadOptions.filter[2] != undefined) {
            //console.log ( "SQL FILTER:"+ gservice.jsToSQLFilter(loadOptions.filter));
            filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
            pp = '&';
          }

        
        if (currentAudit[0] && !loadOptions.auditoverride)
          filter = filter + pp + "audit=" + currentAudit[0];
        else
        if(loadOptions.auditoverride)
          filter = filter + pp + "audit=" + loadOptions.auditoverride;
        else
          filter = filter + pp + "audit=0";


          if(extraFilters[0]) filter = filter + "&evaluateur=" + extraFilters[0];
          if(extraFilters[1]) filter = filter + "&changestatut=" + extraFilters[1];

     //  if(AppConfig.settings.trace) console.log("EvaluationExigenceService load: ventilDataSource calling url: " + SERVICE_URL + "taxo/" + params + filter);

        return http.get<any>(SERVICE_URL + "taxo/" + params + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;

           // if(AppConfig.settings.trace)  console.log("EvaluationExigenceService load: ventilDataSource load response =", response);

            if (response && response != null && response.data && response.data != null) {
              response.data.map(e => {
                e.serie = [
                  { measure: zis.tlabels['common-term*conforme'], count: e.conforme, color: "green" },
                  { measure: zis.tlabels['common-term*non conforme'], count: e.nonconforme, color: "red" },
                  { measure: zis.tlabels['common-term*à vérifier'], count: e.averifier, color: "orange" },
                  { measure: zis.tlabels['common-term*sans objet'], count: e.sansobjet, color: "gray" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "cyan" },
                  // { measure:"total", count: e.total }
                ];

                e.complete = [
                  { measure: zis.tlabels['common-term*déjà évalué'], count: e.evalue, color: "black" },
                  // { measure: this.tlabels['common-term*déjà évalué'], count: (e.sansobjet + e.conforme + e.nonconforme + e.averifier), color: "black" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "yellow" },
                  // { measure:"total", count: e.total }
                ];
              });


              //  console.log("------> EvaluationExigenceService SERVER RESPONSE: ", response.data);
              return response.data
            } else {
              return []
            }
          });
      },

      // totalCount: function(loadOptions:any) { return 0;},
      byKey: function (key) {
       // console.log("byKey: " + AppConfig.settings.api + "/api/taxo/" + encodeURIComponent(key));
        return http.get( AppConfig.settings.api + "/api/taxo/" + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
      },

    });














    this.ventilDataSource_SANS_SANSOBJETS = new CustomStore({

      key: "id",

      load: function (loadOptions: any): any {
        let params = "";
        let filter = "";
        var pp = '?';

      //  if(AppConfig.settings.trace) console.log("ventilDataSource, loadOptions=", loadOptions);

        if (loadOptions.filter && loadOptions.filter.parentIds)
          loadOptions.parentIds = loadOptions.filter.parentIds;

        if (loadOptions.parentIds && loadOptions.parentIds.length == 0)
          loadOptions.parentIds = [0];
        if (loadOptions.parentIds)
          params = "children/" + loadOptions.parentIds.join(","); // picking the last parentId of the list


        if (this.restrictedParents) {
          if(AppConfig.settings.trace) console.log("HIT !");
          params = "children/0?retain=" + this.restrictedParents.join(",");
        }
        else
          if (loadOptions.filter && params.length == 0 && loadOptions.filter[2] && loadOptions.filter[2] != undefined) {
            //console.log ( "SQL FILTER:"+ gservice.jsToSQLFilter(loadOptions.filter));
            filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
            pp = '&';
          }

        
        if (currentAudit[0] && !loadOptions.auditoverride)
          filter = filter + pp + "audit=" + currentAudit[0];
        else
        if(loadOptions.auditoverride)
          filter = filter + pp + "audit=" + loadOptions.auditoverride;
        else
          filter = filter + pp + "audit=0";

       if(AppConfig.settings.trace) console.log("EvaluationExigenceService load: ventilDataSource_SANS_SANSOBJETS calling url: " + SERVICE_URL + "taxo/" + params + filter);

        return http.get<any>(SERVICE_URL + "taxo/" + params + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;

            if(AppConfig.settings.trace)  console.log("EvaluationExigenceService load: ventilDataSource_SANS_SANSOBJETS load response =", response);

            if (response && response.data) {
              response.data.map(e => {
                e.serie = [
                  { measure: zis.tlabels['common-term*conforme'], count: e.conforme, color: "green" },
                  { measure: zis.tlabels['common-term*non conforme'], count: e.nonconforme, color: "red" },
                  { measure: zis.tlabels['common-term*à vérifier'], count: e.averifier, color: "orange" },
               //   { measure: zis.tlabels[''common-term*sans objet'], count: e.sansobjet, color: "gray" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "cyan" },
                  // { measure:"total", count: e.total }
                ];

                e.complete = [
                  { measure: zis.tlabels['common-term*déjà évalué'], count: e.evalue, color: "black" },
                  // { measure: this.tlabels['common-term*déjà évalué'], count: (e.sansobjet + e.conforme + e.nonconforme + e.averifier), color: "black" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "yellow" },
                  // { measure:"total", count: e.total }
                ];
              });


            //  console.log("------> EvaluationExigenceService SERVER RESPONSE: ", response.data);
            if(response!=null)
              return response.data;
              else return [];
            }

            return response;
          });
      },

      // totalCount: function(loadOptions:any) { return 0;},
      byKey: function (key) {
       // console.log("byKey: " + AppConfig.settings.api + "/api/taxo/" + encodeURIComponent(key));
        return http.get( AppConfig.settings.api + "/api/taxo/" + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
      },

    });








    this.usagesDataSource = new CustomStore({

      key: "id",

      load: function (loadOptions: any)  : any {
        let params = "";
        let filter = "";
        var pp = '?';

       // console.log(loadOptions);


        if (loadOptions.filter && params.length == 0 && loadOptions.filter[2] && loadOptions.filter[2] != undefined) {
          var having = undefined;
          if (loadOptions.filter[loadOptions.filter.length - 1] == "having") {

            loadOptions.filter.pop();
            having = loadOptions.filter.pop();
          }

          replaceInFilter(loadOptions.filter, "id", "e.referenceUsage.id");

          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          pp = '&';
          if (having) {
            if(AppConfig.settings.trace) console.log("HAVING FILTER:", having);
            filter = filter + "&having=" + encodeURIComponent(jsToSQLFilter(having));
          }
        } else
          filter = "?filter=0";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }

        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference")
              field = "n.reference.nom";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }


       // console.log("EvaluationExigenceService_usages load: calling url: " + SERVICE_URL + "usage/" + params + filter);

        return http.get<any>(SERVICE_URL + "usage/" + params + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;
         //   console.log("------> EvaluationExigenceService_usages SERVER RESPONSE: ", response.data);
            response.data.map(e => {
            /*
              e.mesures = [
                { measure: "pconforme", count: (e.conforme / e.total) },
                { measure: "pnonconforme", count: (e.nonconforme / e.total) },
                { measure: "pinfo", count: (e.pourinfo / e.total) }];
           */
           
                e.mesures = [
                  { measure: zis.tlabels['common-term*conforme'], count: e.conforme},
                  { measure: zis.tlabels['common-term*non conforme'], count: e.nonconforme },
                  { measure: zis.tlabels['common-term*à vérifier'], count: e.averifier },
                  { measure: zis.tlabels['common-term*sans objet'], count: e.sansobjet },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer },
                  { measure: zis.tlabels['statistiques-abrev*pour info'], count: e.pourinfo },
                  // { measure:"total", count: e.total }
                ];

              });

          
            
            return response;
          });
      },

      // totalCount: function(loadOptions:any) { return 0;},
      byKey: function (key) {
       // console.log("byKey: " +SERVICE_URL + "usage/" + encodeURIComponent(key));
        return http.get(SERVICE_URL + "usage/" + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
      },

    });








    this.exigencesDataSource = new CustomStore({

      key: "id",

      load: function (loadOptions: any) : any {
        let params = "";
        let filter = "";
        var pp = '?';

        if(AppConfig.settings.trace) console.log(loadOptions);


        if (loadOptions.filter && params.length == 0 && loadOptions.filter[2] && loadOptions.filter[2] != undefined) {
          //console.log ( "SQL FILTER:"+ gservice.jsToSQLFilter(loadOptions.filter));
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          pp = '&';
        } else
          filter = "?filter=0";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }

        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "n.reference.nom";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }


 //   if(AppConfig.settings.trace)  console.log("EvaluationExigenceService_EE load: calling url: " + SERVICE_URL + params + filter);

        return http.get<any>(SERVICE_URL + params + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;
         if(AppConfig.settings.trace)   console.log("------> EvaluationExigenceService_EE SERVER RESPONSE==", response.data);

            response.data.map(e => {

           //   e.niveauRealisation = e.niveauRealisation / 100;
           //   e.efficacite = e.efficacite / 100;
              if (e.pourinfo) e.statut = 10;

              switch (e.statut) {
                case 1: e.sstatut = zis.tlabels['common-term*statut-AE']; break;
                case 2: e.sstatut = zis.tlabels['common-term*statut-C']; break;
                case 3: e.sstatut = zis.tlabels['common-term*statut-NC']; break;
                case 4: e.sstatut = zis.tlabels['common-term*statut-SO']; break;
                case 5: e.sstatut = zis.tlabels['common-term*statut-AV']; break;
              }

              if (e.pourinfo)
                e.sstatut = "I";

            });

            return response;
          });
      },

      // totalCount: function(loadOptions:any) { return 0;},
      byKey: function (key) {
     //  console.log("byKey: " + SERVICE_URL + encodeURIComponent(key));
        return http.get(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
      },
      update: function (key, values) {
        if(AppConfig.settings.trace)  console.log("update put: " + SERVICE_URL + encodeURIComponent(key));
        if(AppConfig.settings.trace) console.log("update values:", values);
        return http.put<any>(SERVICE_URL + encodeURIComponent(key), values, { headers: getHttpHeaders() }).toPromise().then(response => {

         
          return {
            result: response ? response.result : {},
            key: response ? response.key : 0
          }
        });

      },
      remove: function (key) {
        // une EE ne opeut être effacée, seule son archive
        return http.delete<any>(SERVICE_URL  + encodeURIComponent(key), { headers: getHttpHeaders()}).toPromise();
      }
    });



    this.multisiteeeDataSource = new CustomStore({

      key: "id",

      load: function (loadOptions: any) : any {
        let params = "multisite/";
        let filter = "?filter=0&currentee=0";

        if(AppConfig.settings.trace)   console.log(loadOptions);


        if (loadOptions.filter) {
          filter = "?filter=" + encodeURIComponent(loadOptions.filter[0]) + "&currentee=" + loadOptions.filter[1];

        } else
          filter = "?filter=0&currentee=0";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }

        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }


        if(AppConfig.settings.trace)  console.log("EvaluationExigenceService_multisiteee load: calling url: " + SERVICE_URL + params + filter);

 //     QSE_USER_INFO droits : site_id:droit
        var infoUser = JSON.parse(sessionStorage.getItem(QSE_USER_INFO));
        let droits:Number = infoUser.droits;

        return http.get<any>(SERVICE_URL + params + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;


            if(AppConfig.settings.trace)
              console.log("------> EvaluationExigenceService_multisiteee SERVER RESPONSE: ", response.data);

            response.data.map(e => {
             // e.niveauRealisation = e.niveauRealisation / 100;
             // e.efficacite = e.efficacite / 100;
              if (e.pourinfo) 
                e.statut = 10;

              switch (e.statut) {
                case 1: e.sstatut = zis.tlabels['common-term*statut-AE']; break;
                case 2: e.sstatut = zis.tlabels['common-term*statut-C']; break;
                case 3: e.sstatut = zis.tlabels['common-term*statut-NC']; break;
                case 4: e.sstatut = zis.tlabels['common-term*statut-SO']; break;
                case 5: e.sstatut = zis.tlabels['common-term*statut-AV']; break;
              }

              if (e.pourinfo)
                e.sstatut = "I";


                // Ici selon les droits utilisateurs d'édition sur les autres sites, il faut définir une variable permettant d'inhiber l'édition
                // dans la table d'audits multisites.
                e.protege=false;
                if(droits.constructor==Number && (+droits) >= 0 && (+droits) <= 6) e.protege=false;
                else
              if(droits) {
                e.protege=true;
                if(droits[e.siteId]) 
                if(droits[e.siteId]==2 || droits[e.siteId]==4||droits[e.siteId]==6||droits[e.siteId]==8) e.protege=false;
              }
             

            });

            return response;
          });
      },

      // totalCount: function(loadOptions:any) { return 0;},
      byKey: function (key) {
        if(AppConfig.settings.trace) console.log("byKey: " + SERVICE_URL + encodeURIComponent(key));
        return http.get(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
      },
      update: function (key, values) {
        if(AppConfig.settings.trace) console.log("update put: " + SERVICE_URL + encodeURIComponent(key));
        if(AppConfig.settings.trace) console.log("update values:", values);
        return http.put<any>(SERVICE_URL + encodeURIComponent(key), values, { headers: getHttpHeaders() }).toPromise().then(response => {

          notify(zis.tlabels['services-common-notify*màj']);
          return {
            result: response.result,
            key: response.key
          }
        });

      }
    });





    this.actionModelDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any) : any {
        let params = "";
        let filter = "";
        var pp = '?';

        if(AppConfig.settings.trace) console.log(loadOptions);

        if (loadOptions.filter && params.length == 0 && loadOptions.filter[2] && loadOptions.filter[2] != undefined) {
          //console.log ( "SQL FILTER:"+ gservice.jsToSQLFilter(loadOptions.filter));
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          pp = '&';
        } else
          filter = "?filter=0";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }

        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "n.reference.nom";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }

        if(AppConfig.settings.trace) console.log("EvaluationExigenceService_ee load: calling url: " + SERVICE_URL + params + filter);

        return http.get<any>(SERVICE_URL + params + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;
            if(AppConfig.settings.trace)  console.log("------> EvaluationExigenceService_ee SERVER RESPONSE: ", response.data);

            response.data.map(e => {
              if (e.pourinfo)
                e.statut = 10;

              switch (e.statut) {
                case 1: e.sstatut = zis.tlabels['common-term*statut-AE']; break;
                case 2: e.sstatut = zis.tlabels['common-term*statut-C']; break;
                case 3: e.sstatut = zis.tlabels['common-term*statut-NC']; break;
                case 4: e.sstatut = zis.tlabels['common-term*statut-SO']; break;
                case 5: e.sstatut = zis.tlabels['common-term*statut-AV']; break;
              }

              if (e.pourinfo)
                e.sstatut = "I";

            });

            return response;
          });
      },

      // totalCount: function(loadOptions:any) { return 0;},
      byKey: function (key) {
        if(AppConfig.settings.trace) console.log("byKey: " + SERVICE_URL + encodeURIComponent(key));
        return http.get(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
      },
      update: function (key, values) {
        if(AppConfig.settings.trace)  console.log("update put: " + SERVICE_URL + encodeURIComponent(key));
        if(AppConfig.settings.trace)  console.log("update values:", values);
        return http.put<any>(SERVICE_URL + encodeURIComponent(key), values, { headers: getHttpHeaders() }).toPromise().then(response => {

          notify(zis.tlabels['services-common-notify*màj']);
          return {
            result: response.result,
            key: response.key
          }
        });

      },
    });






    this.agregeeeDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any) : any {
        let filter = "";

        if(AppConfig.settings.trace) 
          console.log("agregeeeDataSource: agregeeeDataSource loadOptions:", loadOptions);
        if (loadOptions.filter) {
          replaceInFilter(loadOptions.filter,"referenceUsage.nomReference", "e.referenceUsage.reference.nom");
          // Mantis 809 : étendre la recherche dans les actions et le contenu de l'exigence.
          if(loadOptions.filter[0].filterValue!=null) {
          loadOptions.filter = [[loadOptions.filter[0], "OR", ["e.actions", "contains", loadOptions.filter[0].filterValue], "OR", ["e.exigence.derniereVersion.contenu", "contains", loadOptions.filter[0].filterValue]], "AND",loadOptions.filter[2]] ;
          }
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=0";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "n.reference.nom";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }
        if(AppConfig.settings.trace) console.log("EvaluationExigenceService_aggreger load: calling url: " + SERVICE_URL + "aggreger/" + filter);

        return http.get<any>(SERVICE_URL + "aggreger/" + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;
            if(AppConfig.settings.trace)  console.log("------> EvaluationExigenceService_aggreger SERVER RESPONSE: ", response.data);

            response.data.map(e => {

           //   e.niveauRealisation = e.niveauRealisation / 100;
           //   e.efficacite = e.efficacite / 100;
              if (e.pourinfo == 1) e.statut = 10;

              switch (e.statut) {
                case 1: e.sstatut = zis.tlabels['common-term*statut-AE']; break;
                case 2: e.sstatut = zis.tlabels['common-term*statut-C']; break;
                case 3: e.sstatut = zis.tlabels['common-term*statut-NC']; break;
                case 4: e.sstatut = zis.tlabels['common-term*statut-SO']; break;
                case 5: e.sstatut = zis.tlabels['common-term*statut-AV']; break;
              }

              if (e.pourinfo == 1) e.sstatut = "I";


              e.evalexigences.map(f => {
                if (f.pourinfo == 1) f.statut = 10;

                switch (f.statut) {
                  case 1: f.sstatut = zis.tlabels['common-term*statut-AE']; break;
                  case 2: f.sstatut = zis.tlabels['common-term*statut-C']; break;
                  case 3: f.sstatut = zis.tlabels['common-term*statut-NC']; break;
                  case 4: f.sstatut = zis.tlabels['common-term*statut-SO']; break;
                  case 5: f.sstatut = zis.tlabels['common-term*statut-AV']; break;
                }

                if (f.pourinfo) f.sstatut = "I";
              });
            });

            return response;
          });
      },
    });




    this.revisionDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any) : any {
        let filter = "";
        if (loadOptions.filter) {
          replaceInFilter(loadOptions.filter,"referenceUsage.nomReference", "e.referenceUsage.reference.nom");
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=0";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "n.reference.nom";
          
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }
        if(AppConfig.settings.trace)  console.log("EvaluationExigenceService_revisionDataSource load: calling url: " + SERVICE_URL + "revision/" + filter);

        return http.get<any>(SERVICE_URL + "revision/" + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;
            if(AppConfig.settings.trace)   console.log("------> EvaluationExigenceService_revisionDataSource SERVER RESPONSE: ", response.data);

            response.data.map(e => {

            //  e.niveauRealisation = e.niveauRealisation / 100;
            //  e.efficacite = e.efficacite / 100;

              if (e.pourinfo == 1) e.statut = 10;

              switch (e.statut) {
                case 1: e.sstatut = zis.tlabels['common-term*statut-AE']; break;
                case 2: e.sstatut = zis.tlabels['common-term*statut-C']; break;
                case 3: e.sstatut = zis.tlabels['common-term*statut-NC']; break;
                case 4: e.sstatut = zis.tlabels['common-term*statut-SO']; break;
                case 5: e.sstatut = zis.tlabels['common-term*statut-AV']; break;
              }

              if (e.pourinfo == 1) e.sstatut = "I";


              e.evalexigences.map(f => {
                if (f.pourinfo == 1) f.statut = 10;

                switch (f.statut) {
                  case 1: f.sstatut = zis.tlabels['common-term*statut-AE']; break;
                  case 2: f.sstatut = zis.tlabels['common-term*statut-C']; break;
                  case 3: f.sstatut = zis.tlabels['common-term*statut-NC']; break;
                  case 4: f.sstatut = zis.tlabels['common-term*statut-SO']; break;
                  case 5: f.sstatut = zis.tlabels['common-term*statut-AV']; break;
                }

                if (f.pourinfo) f.sstatut = "I";
              });
            });

            return response;
          });
      },
    });





    this.resolutionDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any) : any {
        let filter = "";
        if (loadOptions.filter) {
          replaceInFilter(loadOptions.filter,"referenceUsage.nomReference", "e.referenceUsage.reference.nom");
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=0";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "n.reference.nom";
                   else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }
        if(AppConfig.settings.trace)  console.log("EvaluationExigenceService_aggreger load: calling url: " + SERVICE_URL + "aggreger/" + filter);

        return http.get<any>(SERVICE_URL + "resolution/" + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;
            if(AppConfig.settings.trace)  console.log("------> EvaluationExigenceService_aggreger SERVER RESPONSE: ", response.data);

            response.data.map(e => {

             // e.niveauRealisation = e.niveauRealisation / 100;
             // e.efficacite = e.efficacite / 100;
              if (e.pourinfo == 1) e.statut = 10;

              switch (e.statut) {
                case 1: e.sstatut = zis.tlabels['common-term*statut-AE']; break;
                case 2: e.sstatut = zis.tlabels['common-term*statut-C']; break;
                case 3: e.sstatut = zis.tlabels['common-term*statut-NC']; break;
                case 4: e.sstatut = zis.tlabels['common-term*statut-SO']; break;
                case 5: e.sstatut = zis.tlabels['common-term*statut-AV']; break;
              }

              if (e.pourinfo == 1) e.sstatut = "I";


              e.evalexigences.map(f => {
                if (f.pourinfo == 1) f.statut = 10;

                switch (f.statut) {
                  case 1: f.sstatut = zis.tlabels['common-term*statut-AE']; break;
                  case 2: f.sstatut = zis.tlabels['common-term*statut-C']; break;
                  case 3: f.sstatut = zis.tlabels['common-term*statut-NC']; break;
                  case 4: f.sstatut = zis.tlabels['common-term*statut-SO']; break;
                  case 5: f.sstatut = zis.tlabels['common-term*statut-AV']; break;
                }

                if (f.pourinfo) f.sstatut = "I";
              });
            });

            return response;
          });
      },
    });



    this.pivotDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any)  : any{
        let siteParam = loadOptions.site;
        if (loadOptions.site) {
          siteParam = loadOptions.site;
        } else
          siteParam = "0";

       if(AppConfig.settings.trace) console.log("EvaluationExigenceService_pivot load: calling url: " + SERVICE_URL + "pivot/" + siteParam);

        return http.get<any>(SERVICE_URL + "pivot/" + siteParam, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;
          
            if(AppConfig.settings.trace)
            console.log("------> EvaluationExigenceService_pivot SERVER RESPONSE: ", response.data);

            response.data.map(e => {
            //  console.log("dateParse: " + e.)
           // var dateString = e.dateCreationAudit.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
            var dateString = e.dateCreationAudit.match(/^(\d{2})-(\d{2})-(\d{4})$/);
            if(dateString && dateString.length>3)
              e.dateCreationAudit = new Date( dateString[3], dateString[2]-1, dateString[1] );
            });
            /*
            response.data.map(e => {

              e.niveauRealisation = e.niveauRealisation / 100;
              if (e.pourinfo == 1) e.statut = 10;

              switch (e.statut) {
                case 1: e.sstatut = zis.tlabels['common-term*statut-AE']; break;
                case 2: e.sstatut = zis.tlabels['common-term*statut-C']; break;
                case 3: e.sstatut = zis.tlabels['common-term*statut-NC']; break;
                case 4: e.sstatut = zis.tlabels['common-term*statut-SO']; break;
                case 5: e.sstatut = zis.tlabels['common-term*statut-AV']; break;
              }

              if (e.pourinfo == 1) e.sstatut = "I";


              e.evalexigences.map(f => {
                if (f.pourinfo == 1) f.statut = 10;

                switch (f.statut) {
                  case 1: f.sstatut = zis.tlabels['common-term*statut-AE']; break;
                  case 2: f.sstatut = zis.tlabels['common-term*statut-C']; break;
                  case 3: f.sstatut = zis.tlabels['common-term*statut-NC']; break;
                  case 4: f.sstatut = zis.tlabels['common-term*statut-SO']; break;
                  case 5: f.sstatut = zis.tlabels['common-term*statut-AV']; break;
                }

                if (f.pourinfo) f.sstatut = "I";
              });
            });
            */
            return response;
          });
      },
    });







    this.eeFlatEcheancierDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any) : any {
        let filter = "";
        if (loadOptions.filter) {
          // Remplacement de champs directs en champs indirects
         // console.log(loadOptions.filter);
          replaceInFilter(loadOptions.filter,"titreReference", "e.referenceUsage.reference.nom");
          replaceInFilter(loadOptions.filter,"serviceResponsable", "e.responsable.service");
          replaceInFilter(loadOptions.filter,"nomResponsable", "e.responsable.fullName");
        //  replaceInFilter(loadOptions.filter,"exigenceVersion.contenu", "e.exigenceVersion.contenu");
          transformStatutInFilter(loadOptions.filter);  
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "e.referenceUsage.reference.nom";
            else
            if (loadOptions.sort[i].selector == "serviceResponsable") field = "e.responsable.service";
            else
            if (loadOptions.sort[i].selector == "nomResponsable") field = "e.responsable.fullName";
            else
           // if (loadOptions.sort[i].selector == "exigenceVersion.contenu") field = "e.exigenceVersion.contenu";
           // else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }

      //  console.log("EvaluationExigenceService load: calling url: " + SERVICE_URL + "flat/echeancier/" + currentAudit[0] + filter);

        return http.get<any>(SERVICE_URL + "flat/echeancier/" + currentAudit[0] + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;

             //console.log("------> eeFlatEcheancierDataSource SERVER RESPONSE: ", response.data);

             return response;
          });
      },
    });





    this.eeFlatEcartsDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any)  : any {
        let filter = "";
        if (loadOptions.filter) {
          // Remplacement de champs directs en champs indirects
          replaceInFilter(loadOptions.filter,"titreReference", "e.referenceUsage.reference.nom");
          replaceInFilter(loadOptions.filter,"serviceResponsable", "e.responsable.service");
          replaceInFilter(loadOptions.filter,"nomResponsable", "e.responsable.fullName");
          transformStatutInFilter(loadOptions.filter);  
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "e.referenceUsage.reference.nom";
            else
            if (loadOptions.sort[i].selector == "serviceResponsable") field = "e.responsable.service";
            else
            if (loadOptions.sort[i].selector == "nomResponsable") field = "e.responsable.fullName";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }

      //  console.log("EvaluationExigenceService load: calling url: " + SERVICE_URL + "flat/echeancier/" + currentAudit[0] + filter);

        return http.get<any>(SERVICE_URL + "flat/ecarts/" + currentAudit[0] + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;

            if (response) {
              response.data.map(e => {

              //  e.niveauRealisation = e.niveauRealisation / 100;
              // e.efficacite = e.efficacite / 100;

                e.serie = [
                  { measure: zis.tlabels['common-term*conforme'], count: e.conforme, color: "green" },
                  { measure: zis.tlabels['common-term*non conforme'], count: e.nonconforme, color: "red" },
                  { measure: zis.tlabels['common-term*à vérifier'], count: e.averifier, color: "orange" },
                  { measure: zis.tlabels['common-term*sans objet'], count: e.sansobjet, color: "gray" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "cyan" },
                  // { measure:"total", count: e.total }
                ];

                e.complete = [
                  { measure: zis.tlabels['common-term*déjà évalué'], count: (e.sansobjet + e.conforme + e.nonconforme + e.averifier), color: "black" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "yellow" },
                  // { measure:"total", count: e.total }
                ];
              });
           //   console.log("------> eeFlatEcheancierDataSource SERVER RESPONSE: ", response.data);
              return response;
            }
            return response;
          });
      },
    });




    this.eeFlatConformesDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any) : any {
        let filter = "";
        if (loadOptions.filter) {
          // Remplacement de champs directs en champs indirects
          replaceInFilter(loadOptions.filter,"titreReference", "e.referenceUsage.reference.nom");
          replaceInFilter(loadOptions.filter,"serviceResponsable", "e.responsable.service");
          replaceInFilter(loadOptions.filter,"nomResponsable", "e.responsable.fullName");
          transformStatutInFilter(loadOptions.filter);  
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "e.referenceUsage.reference.nom";
            else
            if (loadOptions.sort[i].selector == "serviceResponsable") field = "e.responsable.service";
            else
            if (loadOptions.sort[i].selector == "nomResponsable") field = "e.responsable.fullName";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }

      //  console.log("EvaluationExigenceService load: calling url: " + SERVICE_URL + "flat/conformes/" + currentAudit[0] + filter);

        return http.get<any>(SERVICE_URL + "flat/conformes/" + currentAudit[0] + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;

            if (response) {
              response.data.map(e => {

              //  e.niveauRealisation = e.niveauRealisation / 100;
              //e.efficacite = e.efficacite / 100;
/*
                e.serie = [
                  { measure: "conforme", count: e.conforme},
                  { measure: this.tlabels['common-term*non conforme'], count: e.nonconforme },
                  { measure: this.tlabels['common-term*à vérifier'], count: e.averifier },
                  { measure: this.tlabels[''common-term*sans objet'], count: e.sansobjet },
                  { measure: this.tlabels['common-term*à évaluer'], count: e.aevaluer},
                  // { measure:"total", count: e.total }
                ];

                e.complete = [
                  { measure: this.tlabels['common-term*déjà évalué'], count: (e.sansobjet + e.conforme + e.nonconforme + e.averifier) },
                  { measure: this.tlabels['common-term*à évaluer'], count: e.aevaluer },
                  // { measure:"total", count: e.total }
                ];
                */
              });
           //   console.log("------> eeFlatEcheancierDataSource SERVER RESPONSE: ", response.data);
              return response;
            }
            return response;
          });
      },
    });





    



    this.eeFlatOpportunitesDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any) : any {
        let filter = "";
        if (loadOptions.filter) {
          // Remplacement de champs directs en champs indirects
          replaceInFilter(loadOptions.filter,"titreReference", "e.referenceUsage.reference.nom");
          replaceInFilter(loadOptions.filter,"serviceResponsable", "e.responsable.service");
          replaceInFilter(loadOptions.filter,"nomResponsable", "e.responsable.fullName");
          transformStatutInFilter(loadOptions.filter);  
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "e.referenceUsage.reference.nom";
            else
            if (loadOptions.sort[i].selector == "serviceResponsable") field = "e.responsable.service";
            else
            if (loadOptions.sort[i].selector == "nomResponsable") field = "e.responsable.fullName";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }

      //  console.log("EvaluationExigenceService load: calling url: " + SERVICE_URL + "flat/conformes/" + currentAudit[0] + filter);

        return http.get<any>(SERVICE_URL + "flat/opportunites/" + currentAudit[0] + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;

            if (response) {
              response.data.map(e => {

               // e.niveauRealisation = e.niveauRealisation / 100;
              // e.efficacite = e.efficacite / 100;
/*
                e.serie = [
                  { measure: "conforme", count: e.conforme},
                  { measure: this.tlabels['common-term*non conforme'], count: e.nonconforme },
                  { measure: this.tlabels['common-term*à vérifier'], count: e.averifier },
                  { measure: this.tlabels[''common-term*sans objet'], count: e.sansobjet },
                  { measure: this.tlabels['common-term*à évaluer'], count: e.aevaluer},
                  // { measure:"total", count: e.total }
                ];

                e.complete = [
                  { measure: this.tlabels['common-term*déjà évalué'], count: (e.sansobjet + e.conforme + e.nonconforme + e.averifier) },
                  { measure: this.tlabels['common-term*à évaluer'], count: e.aevaluer },
                  // { measure:"total", count: e.total }
                ];
                */
              });
           //   console.log("------> eeFlatEcheancierDataSource SERVER RESPONSE: ", response.data);
              return response;
            }
            return response;
          });
      },
    });




    this.eeFlatSansobjetsDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any) : any {
        let filter = "";
        if (loadOptions.filter) {
          // Remplacement de champs directs en champs indirects
          replaceInFilter(loadOptions.filter,"titreReference", "e.referenceUsage.reference.nom");
          replaceInFilter(loadOptions.filter,"serviceResponsable", "e.responsable.service");
          replaceInFilter(loadOptions.filter,"nomResponsable", "e.responsable.fullName");
          transformStatutInFilter(loadOptions.filter);  
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "e.referenceUsage.reference.nom";
            else
            if (loadOptions.sort[i].selector == "serviceResponsable") field = "e.responsable.service";
            else
            if (loadOptions.sort[i].selector == "nomResponsable") field = "e.responsable.fullName";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }

      //  console.log("EvaluationExigenceService load: calling url: " + SERVICE_URL + "flat/sansobjets/" + currentAudit[0] + filter);

        return http.get<any>(SERVICE_URL + "flat/sansobjets/" + currentAudit[0] + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;

            if (response) {
              response.data.map(e => {

               // e.niveauRealisation = e.niveauRealisation / 100;
             // e.efficacite = e.efficacite / 100;
/*
                e.serie = [
                  { measure: "conforme", count: e.conforme},
                  { measure: this.tlabels['common-term*non conforme'], count: e.nonconforme },
                  { measure: this.tlabels['common-term*à vérifier'], count: e.averifier },
                  { measure: this.tlabels[''common-term*sans objet'], count: e.sansobjet },
                  { measure: this.tlabels['common-term*à évaluer'], count: e.aevaluer},
                  // { measure:"total", count: e.total }
                ];

                e.complete = [
                  { measure: this.tlabels['common-term*déjà évalué'], count: (e.sansobjet + e.conforme + e.nonconforme + e.averifier) },
                  { measure: this.tlabels['common-term*à évaluer'], count: e.aevaluer },
                  // { measure:"total", count: e.total }
                ];
                */
              });
           //   console.log("------> eeFlatEcheancierDataSource SERVER RESPONSE: ", response.data);
              return response;
            }
            return response;
          });
      },
    });





    this.eeFlatRevisionsDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any) : any {
        let filter = "";
        if (loadOptions.filter) {
          // Remplacement de champs directs en champs indirects
          replaceInFilter(loadOptions.filter,"titreReference", "e.referenceUsage.reference.nom");
          replaceInFilter(loadOptions.filter,"serviceResponsable", "e.responsable.service");
          replaceInFilter(loadOptions.filter,"nomResponsable", "e.responsable.fullName");
          transformStatutInFilter(loadOptions.filter);  
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "e.referenceUsage.reference.nom";
            else
            if (loadOptions.sort[i].selector == "serviceResponsable") field = "e.responsable.service";
            else
            if (loadOptions.sort[i].selector == "nomResponsable") field = "e.responsable.fullName";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }

      //  console.log("EvaluationExigenceService load: calling url: " + SERVICE_URL + "flat/echeancier/" + currentAudit[0] + filter);

        return http.get<any>(SERVICE_URL + "flat/revisions/" + currentAudit[0] + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;

            if (response) {
              response.data.map(e => {

              //  e.niveauRealisation = e.niveauRealisation / 100;
              //  e.efficacite = e.efficacite / 100;


                e.serie = [
                  { measure: zis.tlabels['common-term*conforme'], count: e.conforme, color: "green" },
                  { measure: zis.tlabels['common-term*non conforme'], count: e.nonconforme, color: "red" },
                  { measure: zis.tlabels['common-term*à vérifier'], count: e.averifier, color: "orange" },
                  { measure: zis.tlabels['common-term*sans objet'], count: e.sansobjet, color: "gray" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "cyan" },
                  // { measure:"total", count: e.total }
                ];

                e.complete = [
                  { measure: zis.tlabels['common-term*déjà évalué'], count: (e.sansobjet + e.conforme + e.nonconforme + e.averifier), color: "black" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "yellow" },
                  // { measure:"total", count: e.total }
                ];
              });
           //   console.log("------> eeFlatEcheancierDataSource SERVER RESPONSE: ", response.data);
              return response;
            }
            return response;
          });
      },
    });






    this.conformesDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any) : any {
        let filter = "";
        if (loadOptions.filter) {
          replaceInFilter(loadOptions.filter,"referenceUsage.nomReference", "e.referenceUsage.reference.nom");
          //console.log ( "SQL FILTER:"+ gservice.jsToSQLFilter(loadOptions.filter));
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=0";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "n.reference.nom";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }
        if(AppConfig.settings.trace)  console.log("EvaluationExigenceService_conformesDataSource load: calling url: " + SERVICE_URL + "conformes/" + filter);

        return http.get<any>(SERVICE_URL + "conformes/" + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;
            if(AppConfig.settings.trace)   console.log("------> EvaluationExigenceService_conformesDataSource SERVER RESPONSE: ", response.data);

            response.data.map(e => {

            //  e.niveauRealisation = e.niveauRealisation / 100;
            //  e.efficacite = e.efficacite / 100;

              if (e.pourinfo == 1) e.statut = 10;

              switch (e.statut) {
                case 1: e.sstatut = zis.tlabels['common-term*statut-AE']; break;
                case 2: e.sstatut = zis.tlabels['common-term*statut-C']; break;
                case 3: e.sstatut = zis.tlabels['common-term*statut-NC']; break;
                case 4: e.sstatut = zis.tlabels['common-term*statut-SO']; break;
                case 5: e.sstatut = zis.tlabels['common-term*statut-AV']; break;
              }

              if (e.pourinfo == 1) e.sstatut = "I";


              e.evalexigences.map(f => {
                if (f.pourinfo == 1) f.statut = 10;

                switch (f.statut) {
                  case 1: f.sstatut = zis.tlabels['common-term*statut-AE']; break;
                  case 2: f.sstatut = zis.tlabels['common-term*statut-C']; break;
                  case 3: f.sstatut = zis.tlabels['common-term*statut-NC']; break;
                  case 4: f.sstatut = zis.tlabels['common-term*statut-SO']; break;
                  case 5: f.sstatut = zis.tlabels['common-term*statut-AV']; break;
                }

                if (f.pourinfo) f.sstatut = "I";
              });
            });

            return response;
          });
      },
    });











    this.conformesDataSourceAvecValidite = new CustomStore({
      key: "id",
      load: function (loadOptions: any) : any {
        let filter = "";
        if (loadOptions.filter) {
          replaceInFilter(loadOptions.filter,"referenceUsage.nomReference", "e.referenceUsage.reference.nom");
          //console.log ( "SQL FILTER:"+ gservice.jsToSQLFilter(loadOptions.filter));
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=0";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "n.reference.nom";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }
        if(AppConfig.settings.trace)  console.log("EvaluationExigenceService_conformesDVDataSource load: calling url: " + SERVICE_URL + "conformesdv/" + filter);

        return http.get<any>(SERVICE_URL + "conformesdv/" + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;
            if(AppConfig.settings.trace)   console.log("------> EvaluationExigenceService_conformesDVDataSource SERVER RESPONSE: ", response.data);

            response.data.map(e => {

            //  e.niveauRealisation = e.niveauRealisation / 100;
            //  e.efficacite = e.efficacite / 100;

              if (e.pourinfo == 1) e.statut = 10;

              switch (e.statut) {
                case 1: e.sstatut = zis.tlabels['common-term*statut-AE']; break;
                case 2: e.sstatut = zis.tlabels['common-term*statut-C']; break;
                case 3: e.sstatut = zis.tlabels['common-term*statut-NC']; break;
                case 4: e.sstatut = zis.tlabels['common-term*statut-SO']; break;
                case 5: e.sstatut = zis.tlabels['common-term*statut-AV']; break;
              }

              if (e.pourinfo == 1) e.sstatut = "I";


              e.evalexigences.map(f => {
                if (f.pourinfo == 1) f.statut = 10;

                switch (f.statut) {
                  case 1: f.sstatut = zis.tlabels['common-term*statut-AE']; break;
                  case 2: f.sstatut = zis.tlabels['common-term*statut-C']; break;
                  case 3: f.sstatut = zis.tlabels['common-term*statut-NC']; break;
                  case 4: f.sstatut = zis.tlabels['common-term*statut-SO']; break;
                  case 5: f.sstatut = zis.tlabels['common-term*statut-AV']; break;
                }

                if (f.pourinfo) f.sstatut = "I";
              });
            });

            return response;
          });
      },
    });






    this.sansobjetsDataSourceAvecValidite = new CustomStore({
      key: "id",
      load: function (loadOptions: any) : any {
        let filter = "";
        if (loadOptions.filter) {
          replaceInFilter(loadOptions.filter,"referenceUsage.nomReference", "e.referenceUsage.reference.nom");

          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=0";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "n.reference.nom";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }
        if(AppConfig.settings.trace)  console.log("EvaluationExigenceService_sansobjetsDVDataSource load: calling url: " + SERVICE_URL + "sansobjetsdv/" + filter);

        return http.get<any>(SERVICE_URL + "sansobjetsdv/" + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;
            if(AppConfig.settings.trace)   console.log("------> EvaluationExigenceService_sansobjetsDVDataSource SERVER RESPONSE: ", response.data);

            response.data.map(e => {

            //  e.niveauRealisation = e.niveauRealisation / 100;
            //  e.efficacite = e.efficacite / 100;

              if (e.pourinfo == 1) e.statut = 10;

              switch (e.statut) {
                case 1: e.sstatut = zis.tlabels['common-term*statut-AE']; break;
                case 2: e.sstatut = zis.tlabels['common-term*statut-C']; break;
                case 3: e.sstatut = zis.tlabels['common-term*statut-NC']; break;
                case 4: e.sstatut = zis.tlabels['common-term*statut-SO']; break;
                case 5: e.sstatut = zis.tlabels['common-term*statut-AV']; break;
              }

              if (e.pourinfo == 1) e.sstatut = "I";


              e.evalexigences.map(f => {
                if (f.pourinfo == 1) f.statut = 10;

                switch (f.statut) {
                  case 1: f.sstatut = zis.tlabels['common-term*statut-AE']; break;
                  case 2: f.sstatut = zis.tlabels['common-term*statut-C']; break;
                  case 3: f.sstatut = zis.tlabels['common-term*statut-NC']; break;
                  case 4: f.sstatut = zis.tlabels['common-term*statut-SO']; break;
                  case 5: f.sstatut = zis.tlabels['common-term*statut-AV']; break;
                }

                if (f.pourinfo) f.sstatut = "I";
              });
            });

            return response;
          });
      },
    });





    this.opportunitesDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any) : any {
        let filter = "";
        if (loadOptions.filter) {
          replaceInFilter(loadOptions.filter,"referenceUsage.nomReference", "e.referenceUsage.reference.nom");
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=0";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "n.reference.nom";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }
        if(AppConfig.settings.trace)  console.log("EvaluationExigenceService_conformesDataSource load: calling url: " + SERVICE_URL + "conformes/" + filter);

        return http.get<any>(SERVICE_URL + "opportunites/" + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;
            if(AppConfig.settings.trace)   console.log("------> EvaluationExigenceService_opportunitesDataSource SERVER RESPONSE: ", response.data);

            response.data.map(e => {

            //  e.niveauRealisation = e.niveauRealisation / 100;
            //  e.efficacite = e.efficacite / 100;

              if (e.pourinfo == 1) e.statut = 10;

              switch (e.statut) {
                case 1: e.sstatut = zis.tlabels['common-term*statut-AE']; break;
                case 2: e.sstatut = zis.tlabels['common-term*statut-C']; break;
                case 3: e.sstatut = zis.tlabels['common-term*statut-NC']; break;
                case 4: e.sstatut = zis.tlabels['common-term*statut-SO']; break;
                case 5: e.sstatut = zis.tlabels['common-term*statut-AV']; break;
              }

              if (e.pourinfo == 1) e.sstatut = "I";


              e.evalexigences.map(f => {
                if (f.pourinfo == 1) f.statut = 10;

                switch (f.statut) {
                  case 1: f.sstatut = zis.tlabels['common-term*statut-AE']; break;
                  case 2: f.sstatut = zis.tlabels['common-term*statut-C']; break;
                  case 3: f.sstatut = zis.tlabels['common-term*statut-NC']; break;
                  case 4: f.sstatut = zis.tlabels['common-term*statut-SO']; break;
                  case 5: f.sstatut = zis.tlabels['common-term*statut-AV']; break;
                }

                if (f.pourinfo) f.sstatut = "I";
              });
            });

            return response;
          });
      },
    });




    this.sansobjetsDataSource = new CustomStore({
      key: "id",
      load: function (loadOptions: any)  : any {
        let filter = "";
        if (loadOptions.filter) {
          replaceInFilter(loadOptions.filter,"referenceUsage.nomReference", "e.referenceUsage.reference.nom");
          filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
        } else
          filter = "?filter=0";

        if (loadOptions.skip) {
          filter = filter + "&skip=" + loadOptions.skip;
        }
        else {
          filter = filter + "&skip=0";
        }
        if (loadOptions.take) {
          filter = filter + "&take=" + loadOptions.take;
        }

        if (loadOptions.sort) {
          var usort = "";
          for (var i = 0; i < loadOptions.sort.length; i++) {
            var field = "";
            if (loadOptions.sort[i].selector == "nomReference") field = "n.reference.nom";
            else
              field = loadOptions.sort[i].selector;

            usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
            if (i + 1 < loadOptions.sort.length)
              usort = usort + ",";
          }
          filter = filter + "&sort=" + encodeURIComponent(usort);
        }
        if(AppConfig.settings.trace)  console.log("EvaluationExigenceService_conformesDataSource load: calling url: " + SERVICE_URL + "sansobjets/" + filter);

        return http.get<any>(SERVICE_URL + "sansobjets/" + filter, { headers: getHttpHeaders() }).toPromise()
          .then(response => {
            this.restrictedParents = undefined;
            if(AppConfig.settings.trace)   console.log("------> EvaluationExigenceService_conformesDataSource SERVER RESPONSE: ", response.data);

            response.data.map(e => {

             // e.niveauRealisation = e.niveauRealisation / 100;
             // e.efficacite = e.efficacite / 100;

              if (e.pourinfo == 1) e.statut = 10;

              switch (e.statut) {
                case 1: e.sstatut = zis.tlabels['common-term*statut-AE']; break;
                case 2: e.sstatut = zis.tlabels['common-term*statut-C']; break;
                case 3: e.sstatut = zis.tlabels['common-term*statut-NC']; break;
                case 4: e.sstatut = zis.tlabels['common-term*statut-SO']; break;
                case 5: e.sstatut = zis.tlabels['common-term*statut-AV']; break;
              }

              if (e.pourinfo == 1) e.sstatut = "I";


              e.evalexigences.map(f => {
                if (f.pourinfo == 1) f.statut = 10;

                switch (f.statut) {
                  case 1: f.sstatut = zis.tlabels['common-term*statut-AE']; break;
                  case 2: f.sstatut = zis.tlabels['common-term*statut-C']; break;
                  case 3: f.sstatut = zis.tlabels['common-term*statut-NC']; break;
                  case 4: f.sstatut = zis.tlabels['common-term*statut-SO']; break;
                  case 5: f.sstatut = zis.tlabels['common-term*statut-AV']; break;
                }

                if (f.pourinfo) f.sstatut = "I";
              });
            });

            return response;
          });
      },
    });











    this.groupeStatsDataSource = new CustomStore({

      key: "id",

      load: function (loadOptions: any): any {
       
        if(AppConfig.settings.trace) console.log("groupeStatsDataSource, loadOptions=", loadOptions);
        let client = loadOptions.filter[0];
        let mode = loadOptions.filter[1];
        
        return http.get<any>(AppConfig.settings.api +"/api/ee/groupe/stats/"  + client + "/" + mode, { headers: getHttpHeaders() }).toPromise()
          .then(response => {

            if (response && response.data) {
              response.data.map(e => {
                e.serie = [
                  { measure: zis.tlabels['common-term*conforme'], count: e.conforme, color: "green" },
                  { measure: zis.tlabels['common-term*non conforme'], count: e.nonconforme, color: "red" },
                  { measure: zis.tlabels['common-term*à vérifier'], count: e.averifier, color: "orange" },
               //   { measure: zis.tlabels[''common-term*sans objet'], count: e.sansobjet, color: "gray" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "cyan" },
                  // { measure:"total", count: e.total }
                ];

                e.complete = [
                  { measure: zis.tlabels['common-term*déjà évalué'], count: e.evalue, color: "black" },
                  // { measure: this.tlabels['common-term*déjà évalué'], count: (e.sansobjet + e.conforme + e.nonconforme + e.averifier), color: "black" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "yellow" },
                  // { measure:"total", count: e.total }
                ];
                e.pie1 = [
                  { measure: zis.tlabels['common-term*conforme'], count: e.conforme },
                  { measure: zis.tlabels['common-term*non conforme'], count: e.nonconforme },
                  { measure: zis.tlabels['common-term*à vérifier'], count: e.averifier },
                  //        { measure: this.tlabels[''common-term*sans objet'], count: paraudit.sansobjet },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer }
                ];
        
                e.pie2 = [
                  { measure: zis.tlabels['common-term*déjà évalué'], count: e.evalue },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer }
                ];
              });
           //  console.log("------> EvaluationExigenceService SERVER RESPONSE: ", response.data);
            if(response!=null)
              return response.data;
              else return [];
            }

            return response;
          });
      }
    });






    this.parReferenceStatsDataSource = new CustomStore({

      key: "id",

      load: function (loadOptions: any): any {
       
        if(AppConfig.settings.trace) console.log("parReferenceStatsDataSource, loadOptions=", loadOptions);
        let client = loadOptions.filter[0];
        let mode = loadOptions.filter[1];
        let usages = loadOptions.filter[2];
        
        return http.get<any>(AppConfig.settings.api +"/api/ee/usages/stats/"  + client + "/" + mode + "?usages=" + encodeURIComponent(usages), { headers: getHttpHeaders() }).toPromise()
          .then(response => {

            if (response && response.data) {

            //  console.log("Par Ref DS ", response.data)

              response.data.map(e => {
                e.serie = [
                  { measure: zis.tlabels['common-term*conforme'], count: e.conforme, color: "green" },
                  { measure: zis.tlabels['common-term*non conforme'], count: e.nonconforme, color: "red" },
                  { measure: zis.tlabels['common-term*à vérifier'], count: e.averifier, color: "orange" },
               //   { measure: zis.tlabels[''common-term*sans objet'], count: e.sansobjet, color: "gray" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "cyan" },
                  // { measure:"total", count: e.total }
                ];

                e.complete = [
                  { measure: zis.tlabels['common-term*déjà évalué'], count: e.evalue, color: "black" },
                  // { measure: this.tlabels['common-term*déjà évalué'], count: (e.sansobjet + e.conforme + e.nonconforme + e.averifier), color: "black" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "yellow" },
                  // { measure:"total", count: e.total }
                ];



                e.pie1 = [
                  { measure: zis.tlabels['common-term*conforme'], count: e.conforme },
                  { measure: zis.tlabels['common-term*non conforme'], count: e.nonconforme },
                  { measure: zis.tlabels['common-term*à vérifier'], count: e.averifier },
                  //        { measure: this.tlabels[''common-term*sans objet'], count: paraudit.sansobjet },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer }
                ];
        
                e.pie2 = [
                  { measure: zis.tlabels['common-term*déjà évalué'], count: e.evalue },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer }
                ];
              });
            if(response!=null)
              return response.data;
              else return [];
            }

            return response;
          });
      }
    });




    this.multisiteStatsDataSource = new CustomStore({

      key: "id",

      load: function (loadOptions: any): any {
       
     //   if(AppConfig.settings.trace) console.log("groupeStatsDataSource, loadOptions=", loadOptions);
        let client = loadOptions.filter[0];
        let mode = loadOptions.filter[1];
        
        return http.get<any>(AppConfig.settings.api +"/api/ee/client/stats/"  + client + "/" + mode, { headers: getHttpHeaders() }).toPromise()
          .then(response => {

            if (response && response.data) {
              response.data.map(e => {
                e.serie = [
                  { measure: zis.tlabels['common-term*conforme'], count: e.conforme, color: "green" },
                  { measure: zis.tlabels['common-term*non conforme'], count: e.nonconforme, color: "red" },
                  { measure: zis.tlabels['common-term*à vérifier'], count: e.averifier, color: "orange" },
               //   { measure: zis.tlabels[''common-term*sans objet'], count: e.sansobjet, color: "gray" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "cyan" },
                  // { measure:"total", count: e.total }
                ];

                e.complete = [
                  { measure: zis.tlabels['common-term*déjà évalué'], count: e.evalue, color: "black" },
                  // { measure: this.tlabels['common-term*déjà évalué'], count: (e.sansobjet + e.conforme + e.nonconforme + e.averifier), color: "black" },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer, color: "yellow" },
                  // { measure:"total", count: e.total }
                ];



                e.pie1 = [
                  { measure: zis.tlabels['common-term*conforme'], count: e.conforme },
                  { measure: zis.tlabels['common-term*non conforme'], count: e.nonconforme },
                  { measure: zis.tlabels['common-term*à vérifier'], count: e.averifier },
                  //        { measure: this.tlabels[''common-term*sans objet'], count: paraudit.sansobjet },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer }
                ];
        
                e.pie2 = [
                  { measure: zis.tlabels['common-term*déjà évalué'], count: e.evalue },
                  { measure: zis.tlabels['common-term*à évaluer'], count: e.aevaluer }
                ];
              });


            //  console.log("------> EvaluationExigenceService SERVER RESPONSE: ", response.data);
            if(response!=null)
              return response.data;
              else return [];
            }

            return response;
          });
      }

   
    });

  }



  getVentilRestDataSource() {
    return this.ventilDataSource;
  }
  getventilDataSource_SANS_SANSOBJETSRestDataSource() {
    return this.ventilDataSource_SANS_SANSOBJETS;
  }

  


  getReferenceUsageRestDataSource() {
    return this.usagesDataSource;
  }


  getEvaluationExigencesRestDataSource() {
    return this.exigencesDataSource;
  }

  getPrioriteDataSource() {
    return _PrioriteDataSource;
  }

  getStatutDataSource() {
    return _StatutDataSource;
  }

  getStatutOpportuniteDataSource() {
    return _StatutOpportuniteDataSource;
  }

  getChangeStatutDataSource() {
    return _ChangeStatutDataSource;
  }

  getStatutInfoDataSource() {
    return _StatutInfoDataSource;
  }

  getStatutFilterDataSource() {
    return _StatutFilterDataSource;
  }

  getStatutFilterDataSourceCS() {
    return _StatutFilterDataSourceCS;
  }
  

  getRefUsageFilterDataSource() {
    return _RefUsageFilterDataSource;
  }

  getActionModelDataSource() {
    return this.actionModelDataSource;
  }

  getPropagationActionDataSource() {
    return _propagationActionDataSource;
  }

  getMultisiteEEDataSource() {
    return this.multisiteeeDataSource;
  }

  getAgregeeeDataSource() {
    return this.agregeeeDataSource;
  }

  getResolutionDataSource() {
    return this.resolutionDataSource;
  }

  getConformesDataSource() {
    return this.conformesDataSource;
  }

  getConformesDVDataSource() {
    return this.conformesDataSourceAvecValidite;
  }


  getSansObjetDVDataSource() {
    return this.sansobjetsDataSourceAvecValidite;
  }


  getOpportunitesDataSource() {
    return this.opportunitesDataSource;
  }


  getSansobjetsDataSource() {
    return this.sansobjetsDataSource;
  }

  

  getRevisionDataSource() {
    return this.revisionDataSource;
  }

  getPivotDataSource() {
    return this.pivotDataSource;
  }


 getEEFlatEcheancierDataSource() {
  return this.eeFlatEcheancierDataSource;
}
getEEFlatEcartsDataSource() {
  return this.eeFlatEcartsDataSource;
}
getEEFlatRevisionDataSource() {
  return this.eeFlatRevisionsDataSource;
}

getEEFlatConformesDataSource() {
  return this.eeFlatConformesDataSource;
}

getEEFlatOpportunitesDataSource() {
  return this.eeFlatOpportunitesDataSource;
}

getEEFlatSansobjetsDataSource() {
  return this.eeFlatSansobjetsDataSource;
}

getGroupeStatsDataSource() {
  return this.groupeStatsDataSource;
}

getParReferenceStatsDataSource() {
  return this.parReferenceStatsDataSource;
}

getMultisiteStatsDataSource() {
  return this.multisiteStatsDataSource;
}
}
