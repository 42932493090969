import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReferenceUsageService } from 'app/services/reference-usage.service';
import { DxButtonComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ref-nav',
  templateUrl: './ref-nav.component.html',
  styleUrls: ['./ref-nav.component.scss']
})
export class RefNavComponent implements OnInit {

  @Input() hasNext: boolean = false
  @Input() hasPrevious: boolean = false

  @ViewChild('prevReference') prevRef: DxButtonComponent
  @ViewChild('nextReference') nextRef: DxButtonComponent

  constructor(private usageService: ReferenceUsageService) { }

  ngOnInit(): void {
  }

  previousReferenceMove(e: any) {
    e.next = true
    this.usageService.currentUsageSubject.next(e)
   }


  nextReferenceMove(e: any) {
    e.next = false
    this.usageService.currentUsageSubject.next(e)
   }

}
